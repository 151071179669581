<template>
    <div class="wrapper_dialog luckyStarRightNav">
        <transition name="fade" mode="out-in" appear>
        <div class="relative space_between">
            <div class="container_left" @click="closeNavDialog"></div>
            <div class="container_right">
                <div class="top">
                    <a href="javascript:;" class="logo_r"><img src="../../../../../public/images/lukyStar/logo.png" alt=""></a>
                    <a href="javascript:;" class="close_r" @click="closeNavDialog"><img src="../../../../../public/images/icon_close.png" alt=""></a>
                </div>
                <div class="adress_box">
                  <div class="title">Address</div>
                  <div class="center_ad">
                    <div class="connected_ad" v-if="connectedStatus==1">
                      <el-row type="flex" class="row-bg" justify="space-between">
                        <el-col :span="21" class="desc">{{address}}</el-col>
                        <el-col :span="3" class="copy tr"><img src="../../../../../public/images/fizhi.png" alt=""></el-col>
                      </el-row>
                    </div>
                    <div class="not_connected_ad" v-if="connectedStatus==0">
                      <div class="line">- - - - - - </div>
                    </div>
                  </div>
                  <div class="status_ad" v-if="connectedStatus==1">{{$t('menu.zhuangtai')}}{{$t('menu.zt_desc_a')}}</div>
                  <div class="status_ad" v-if="connectedStatus==0">{{$t('menu.zhuangtai')}}{{$t('menu.zt_desc_b')}}</div>
                  <div class="connected_ad_btn" v-if="connectedStatus==0"><a href="javascript:;" @click="openDialog(connectedStatus,1)">{{$t('menu.connect')}}</a></div>
                </div>
                <div class="right_nav_list">
                  <router-link v-for="(item,index) in routerList" :key="index" :to="{name:item.name,path:item.path}">
                    <span class="list_l"><img :src="item.icon" alt=""><i>{{$t("menu." +item.title)}}</i></span>
                    <img class="right_icon_s" src="../../../../../public/images/right.png" alt="">
                  </router-link>
                  <a href="javascript:;" @click="changeLan">
                    <span class="list_l"><img src="../../../../../public/images/lukyStar/icon-yuyan.png" alt=""><i>{{$t('menu.yysz')}}</i></span>
                    <span class="en_fs"><i>{{$t('menu.langChange')}}</i><img class="right_icon_s" src="../../../../../public/images/lukyStar/icon-qiehuan.png" alt=""></span>
                  </a>
                </div>
            </div>
        </div>
        </transition>
        <div v-if="dialogShow"><Star-Wallet :id="0" @transfer="openDialog"></Star-Wallet></div>
    </div>
</template>

<script>
import '../styles/StarRight.css';
import '../../../../style/rightNav.css';
import StarWallet from './StarWallet.vue';
export default {
  name: 'StarRight',
  components:{StarWallet},
  props:['conStatus'],
  computed: {
    normalizedSize: function () {
      return this.conStatus
    } 
  },
  watch:{
    connectedStatus(newVal){
      // newVal是新值，oldVal是旧值
      this.conStatus = newVal;
    }
  },
  data() {
    return {
      address:'',
      connectedStatus:this.conStatus, //0未连接  1已连接
      dialogShow:false,//是否弹出钱包窗口
      routerList:[
      {
        name:'ExchangeLuckyStars',
        path:'/exchangeLuckyStars',
        icon:require("../../../../../public/images/lukyStar/icon-dhxyx.png"),
        title:'dhxyx'
      },{
        name:'LuckyStarRecord',
        path:'/luckyStarRecord',
        icon:require("../../../../../public/images/lukyStar/icon-xyxjl.png"),
        title:'xyxjl'
      },{
        name:'LuckyStarGameplay',
        path:'/luckyStarGameplay',
        icon:require("../../../../../public/images/lukyStar/icon-xyxwf.png"),
        title:'xyxwf'
      }
      ]
    }
  },
  created:function(){
    this.address = localStorage.getItem('address')
    if(this.address){
      this.connectedStatus = 1
    }
  },
  methods: {
    closeNavDialog:function(){
       if(this.connectedStatus==0){
          this.$emit('transfer',0,2)//向父组件TopNav.vue传递的数据connectStatus=0,type=2
        }
        if(this.connectedStatus==1){
          this.$emit('transfer',1,2)//向父组件TopNav.vue传递的数据connectStatus=1,type=2
        }
    },
    openDialog(connectStatus,type){
      if(type==1){//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow=true;
        console.log(connectStatus,'==',type);
      }
      if(type==2){
        this.dialogShow=false;
        this.connectedStatus = connectStatus;
        this.closeNavDialog();
      }
    },
    changeLan(){
      // console.log(this.$i18n,'=============');
      let command = this.$i18n.locale;
      if(command == 'en') {
          this.$i18n.locale = 'zh';
      } else if (command == 'zh') {
          this.$i18n.locale = 'en';
      }
    }
  },
}
</script>

<style>
.wrapper_dialog .container_right {
  background: rgb(7, 7, 7)!important;
  background-size: 100% 100%;
}
</style>